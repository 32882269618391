.container-home {
    margin-top: 20px;
    min-height: 100vh;
}

.container-services {
    margin-top: 20px;
    min-height: 100vh;
}

.container-about {
    margin-top: 20px;
    min-height: 100vh;
}

.container-contact {
    margin-top: 20px;
    min-height: 100vh;
}

.container-blogs {
    margin-top: 20px;
    min-height: 100vh;
}

.carousel .slide {
    background-color: rgb(255, 255, 255);
}

.carousel .slide img {
    max-height: 500px;
}

.carousel .slide .thumbils img {
    filter: blur(8px);
}

.carousel .slide.selected .thumbils img {
    filter: none;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3.MuiGrid-justify-xs-center {
    width: 100%;
}

.carousel .slide .MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
    filter: blur(8px);
}

.carousel .slide.selected .MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
    filter: none;
}

.section2p {
    font-size: 1.5rem;
}

.section3 p {
    margin-bottom: 10px;
    font-size: 1rem;
}

.subscribe-section
.section2-content {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    max-width: 50%;
    max-height: 50%;
}

.email {
    border-radius: 20px;
    font-size: 15px;
    margin-top: 15px;
    height: 50px;
    width: 60%;
    background: none;
    outline: none;
}

.signup-btn {
    border-radius: 20px;
    font-size: 15px;
    font-weight: bold;
    margin-top: 15px;
    height: 50px;
    width: 100px;
    margin-left: 20px;
    background-color: rgb(24, 120, 185);
}

.signup-btn:hover {
    background-color: rgb(197, 138, 138);
    color: #242424;
    border-radius: 10;
}

.ecg-image {
    width: 100%;
    height: auto;
}

.certificate-image {
    width: 70%;
    height: auto;
}

.container-services h1 {
    font-weight: normal;
}

.teamList
.specialty {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.teamList li {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    list-style-type: none;
}

.specialty li {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    list-style-type: none;
}

.form-download {
    font-size: 25px;
    text-align: center;
}

.form-download a {
    text-decoration: none;
}

.messageBox {
    text-align: center;
}

.container-contact h4 a {
    text-decoration: none;
}

.share-buttons {
    text-decoration: none;
}

@media screen and (max-width: 900px) {
    .email {
        max-width: 50%;
    }

    .container-services h1 {
        font-weight: normal;
        font-size: 25px;
    }
}
