.admin-navbar-header {
    background: linear-gradient(
        90deg,
        rgba(123, 204, 241, 0.897) 0%,
        rgb(123, 207, 240) 100%
    );
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: relative;
    top: 0;
    z-index: 999;
}

.admin-navbar-logo {
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    height: 95px;
}

.admin-navbar {
    background: linear-gradient(
        90deg,
        rgba(205, 230, 241, 0.897) 0%,
        rgb(212, 227, 233) 100%
    );
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 999;
}

.admin-nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-right: 5rem;
}

.admin-nav-links {
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1.5rem;
}

.admin-nav-links:hover {
    border-bottom: 4px solid rgb(50, 52, 53);
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: black;
}

.menu-icon {
    display: none;
}

.logo {
    justify-self: start;
    margin-left: 3rem;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    height: 115px;
}

.logo:hover {
    background: rgba(255,255,255,0.05);
	-o-transform: scale(0.93);
	-webkit-transform: scale(0.93);
	-ms-transform: scale(0.93);
	transform: scale(0.93);
}

@media screen and (max-width: 950px) {
    .admin-navbar {
        position: relative;
    }

    .logo {
        position: relative;
        display: flex;
        margin-left: 0rem;
      }

    .admin-nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 90px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .admin-nav-menu.active {
        background: #37383a;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        justify-content: end;
        z-index: 1;
    }

    .admin-nav-links {
        color: #fff;
        text-align: center;
        padding: 2rem;
        width: 100vw;
        display: table;
    }

    .admin-nav-links:hover {
        background-color: rgb(67, 88, 95);
        border-bottom: none;
        border-radius: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 25%;
        right: 4%;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: black;
        font-size: 2rem;
    }
}
